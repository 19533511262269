// Generated by ReScript, PLEASE EDIT WITH CARE
'use strict';

var Curry = require("rescript/lib/js/curry.js");
var Utils = require("../../utils/Utils.bs.js");
var React = require("react");
var Sentry = require("../Sentry.bs.js");
var Js_dict = require("rescript/lib/js/js_dict.js");
var Js_json = require("rescript/lib/js/js_json.js");
var Js_array = require("rescript/lib/js/js_array.js");
var Core__List = require("@rescript/core/src/Core__List.bs.js");
var ApolloHooks = require("reason-apollo-hooks/src/ApolloHooks.bs.js");
var Caml_option = require("rescript/lib/js/caml_option.js");
var Core__Option = require("@rescript/core/src/Core__Option.bs.js");
var Core__Promise = require("@rescript/core/src/Core__Promise.bs.js");
var JsxRuntime = require("react/jsx-runtime");
var Caml_js_exceptions = require("rescript/lib/js/caml_js_exceptions.js");

var features = {
  hd: [
    "BillingPreferencePlanDowngrade",
    "billing_preference_plan_downgrade"
  ],
  tl: {
    hd: [
      "CancelProMembership",
      "cancel_pro_membership"
    ],
    tl: {
      hd: [
        "ConversationMessageStatus",
        "conversation_message_status"
      ],
      tl: {
        hd: [
          "DuplicateListings",
          "duplicate_listings"
        ],
        tl: {
          hd: [
            "MLSCheckboxesAlwaysEnabled",
            "mls_checkboxes_always_enabled"
          ],
          tl: {
            hd: [
              "SaveAsBuyer",
              "save_as_buyer"
            ],
            tl: {
              hd: [
                "SetConversationAnonymousStatus",
                "set_conversation_anonymous_status"
              ],
              tl: {
                hd: [
                  "ValidatePersonalInfoInDescription",
                  "validate_personal_info_in_description"
                ],
                tl: {
                  hd: [
                    "HiddenSellers",
                    "hidden_sellers"
                  ],
                  tl: {
                    hd: [
                      "UnhideSeller",
                      "unhide_seller"
                    ],
                    tl: {
                      hd: [
                        "CAP_ClientRegistration",
                        "cap_client_registration"
                      ],
                      tl: {
                        hd: [
                          "SavedSearches",
                          "saved_searches"
                        ],
                        tl: {
                          hd: [
                            "RenameClient",
                            "rename_client"
                          ],
                          tl: {
                            hd: [
                              "OffMlsFilterForCAP",
                              "off_mls_filter_for_cap"
                            ],
                            tl: {
                              hd: [
                                "MarketingProfileSettings",
                                "marketing_profile_settings"
                              ],
                              tl: {
                                hd: [
                                  "SwitchTeamToAgentMarketingProfile",
                                  "switch_team_to_agent_marketing_profile"
                                ],
                                tl: {
                                  hd: [
                                    "RelayMessageBroadcast",
                                    "relay_message_broadcast"
                                  ],
                                  tl: {
                                    hd: [
                                      "RelayClientNeedPage",
                                      "relay_client_need_page"
                                    ],
                                    tl: {
                                      hd: [
                                        "RelayPropertiesPage",
                                        "relay_properties_page"
                                      ],
                                      tl: {
                                        hd: [
                                          "RelayClientNeedsPage",
                                          "relay_client_needs_page"
                                        ],
                                        tl: {
                                          hd: [
                                            "RelayConversationsPage",
                                            "relay_conversations_page"
                                          ],
                                          tl: /* [] */0
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
};

function fromString(v) {
  return Core__List.getAssoc(Core__List.map(features, (function (param) {
                    return [
                            param[1],
                            param[0]
                          ];
                  })), v, (function (prim0, prim1) {
                return prim0 === prim1;
              }));
}

function key(t) {
  return Core__Option.getExn(Core__List.getAssoc(features, t, (function (prim0, prim1) {
                    return prim0 === prim1;
                  })));
}

var allKeys = Core__List.map(features, (function (param) {
        return param[1];
      }));

var Feature = {
  features: features,
  fromString: fromString,
  key: key,
  allKeys: allKeys
};

var overrides = (JSON.parse(process.env.NEXT_PUBLIC__FEATURES__));

function get(param) {
  return overrides[param];
}

function override(param) {
  var feature = param[0];
  var override$1 = overrides[key(feature)];
  return [
          feature,
          Core__Option.isSome(override$1) ? override$1 : param[1]
        ];
}

var x = Core__List.fromArray(Utils.$$Array.stripNones(Object.keys(overrides).map(function (k) {
              var f = fromString(k);
              return Core__Option.map(f, (function (f$p) {
                            return [
                                    f$p,
                                    overrides[k]
                                  ];
                          }));
            })));

var $$default = Core__List.length(x) > 0 ? x : undefined;

var Override = {
  overrides: overrides,
  get: get,
  override: override,
  $$default: $$default
};

function variant(f, t) {
  return Core__Option.flatMap(Core__List.getAssoc(t, f, (function (prim0, prim1) {
                    return prim0 === prim1;
                  })), (function (x) {
                return x;
              }));
}

function variantFromKey(k, t) {
  return Core__Option.flatMap(fromString(k), (function (k) {
                return variant(k, t);
              }));
}

function make(jsProps) {
  return Core__Option.map(jsProps, (function (arr) {
                return Core__List.map(Core__List.filter(Core__List.fromArray(arr.map(function (e) {
                                        return Core__Option.map(e, (function (o) {
                                                      return [
                                                              Core__Option.getExn(fromString(o.flagKey)),
                                                              o.variantKey
                                                            ];
                                                    }));
                                      })), Core__Option.isSome), Core__Option.getExn);
              }));
}

var Features = {
  variant: variant,
  variantFromKey: variantFromKey,
  make: make
};

var context = React.createContext(undefined);

function useFeatureFlipperState(refresh, features, token, initializing, isLoggingOut) {
  var match = React.useState(function (param) {
        
      });
  var setState = match[1];
  var prevTokenRef = React.useRef(token);
  React.useEffect((function (param) {
          if (features !== undefined) {
            Curry._1(setState, (function (param) {
                    return features;
                  }));
          }
          
        }), [features]);
  React.useEffect((function (param) {
          var match = prevTokenRef.current;
          if (match !== undefined) {
            
          } else if (token !== undefined) {
            if (isLoggingOut) {
              
            } else {
              Curry._1(refresh, undefined);
            }
          } else if (initializing || isLoggingOut) {
            
          } else {
            Curry._1(refresh, undefined);
          }
          Utils.React.setCurrentRef(prevTokenRef, token);
        }), [
        token,
        initializing,
        isLoggingOut
      ]);
  return match[0];
}

function useFeatureFlipper(flagKey) {
  var features = React.useContext(context);
  return Core__Option.flatMap(features, (function (param) {
                return variant(flagKey, param);
              }));
}

var ppx_printed_query = "mutation evaluateKeys($keys: [String!]!)  {\nevaluateFlags(keys: $keys)  {\nflagKey  \nvariantKey  \n}\n\n}\n";

function parse(value) {
  var value$1 = value["evaluateFlags"];
  return {
          evaluateFlags: make(!(value$1 == null) ? Js_array.map((function (value) {
                        if (value == null) {
                          return ;
                        }
                        var value$1 = value["variantKey"];
                        return {
                                flagKey: value["flagKey"],
                                variantKey: !(value$1 == null) ? value$1 : undefined
                              };
                      }), value$1) : undefined)
        };
}

function make$1(keys, param) {
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "keys",
                      Js_array.map((function (prim) {
                              return prim;
                            }), keys)
                    ]])),
          parse: parse
        };
}

function makeWithVariables(variables) {
  var keys = variables.keys;
  return {
          query: ppx_printed_query,
          variables: Js_dict.fromArray(Js_array.filter((function (param) {
                      return !Js_json.test(param[1], "Null");
                    }), [[
                      "keys",
                      Js_array.map((function (prim) {
                              return prim;
                            }), keys)
                    ]])),
          parse: parse
        };
}

function makeVariables(keys, param) {
  return Js_dict.fromArray(Js_array.filter((function (param) {
                    return !Js_json.test(param[1], "Null");
                  }), [[
                    "keys",
                    Js_array.map((function (prim) {
                            return prim;
                          }), keys)
                  ]]));
}

function definition_2(graphql_ppx_use_json_variables_fn, keys, param) {
  return Curry._1(graphql_ppx_use_json_variables_fn, Js_dict.fromArray(Js_array.filter((function (param) {
                        return !Js_json.test(param[1], "Null");
                      }), [[
                        "keys",
                        Js_array.map((function (prim) {
                                return prim;
                              }), keys)
                      ]])));
}

var definition = [
  parse,
  ppx_printed_query,
  definition_2
];

function ret_type(f) {
  return {};
}

var MT_Ret = {};

var EvaluateKeys = {
  ppx_printed_query: ppx_printed_query,
  query: ppx_printed_query,
  parse: parse,
  make: make$1,
  makeWithVariables: makeWithVariables,
  makeVariables: makeVariables,
  definition: definition,
  ret_type: ret_type,
  MT_Ret: MT_Ret
};

function refresh(mutate, param) {
  var e = make$1(Core__List.toArray(allKeys), undefined);
  return Core__Promise.$$catch(Curry._6(mutate, e.variables, undefined, undefined, undefined, undefined, undefined).then(function (param) {
                  return Promise.resolve();
                }), (function (e) {
                Sentry.captureException(Core__Option.getOr(Caml_js_exceptions.as_js_exn(e), new Error("An unexpected error occurred")), "FeaturesV3.res", 173, {
                      exn: e
                    }, undefined);
                return Promise.resolve();
              }));
}

var make$2 = context.Provider;

var Provider = {
  make: make$2
};

function FeaturesV3$Provider(props) {
  var match = ApolloHooks.useMutation(undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, definition);
  var renderProps = match[2];
  var mutate = match[0];
  var features = React.useMemo((function (param) {
          var features = Core__Option.flatMap(renderProps.data, (function (x) {
                  return x.evaluateFlags;
                }));
          var features$1 = Core__Option.map(features, (function (x) {
                  return Core__List.map(x, override);
                }));
          if (Core__Option.isSome(features$1)) {
            return features$1;
          } else {
            return $$default;
          }
        }), [JSON.stringify(renderProps.data)]);
  var refresh$1 = React.useCallback((function (param) {
          return refresh(mutate, param);
        }), [mutate]);
  var features$1 = useFeatureFlipperState(refresh$1, features, props.token, props.initializing, props.isLoggingOut);
  return JsxRuntime.jsx(make$2, {
              value: features$1,
              children: props.children
            });
}

var Provider$1 = {
  refresh: refresh,
  Provider: Provider,
  make: FeaturesV3$Provider
};

function make$3(param) {
  var token = Caml_option.nullable_to_opt(param.token);
  return JsxRuntime.jsx(FeaturesV3$Provider, {
              token: token,
              initializing: param.initializing,
              isLoggingOut: param.isLoggingOut,
              children: param.children
            });
}

var JsProvider = {
  make: make$3
};

function useSavedSearchesEnabled(param) {
  return useFeatureFlipper("SavedSearches") === "on";
}

function useRelayConversationsPageEnabled(param) {
  return useFeatureFlipper("RelayConversationsPage") === "on";
}

var jsProvider = make$3;

exports.Feature = Feature;
exports.Override = Override;
exports.Features = Features;
exports.context = context;
exports.useFeatureFlipperState = useFeatureFlipperState;
exports.useFeatureFlipper = useFeatureFlipper;
exports.EvaluateKeys = EvaluateKeys;
exports.Provider = Provider$1;
exports.JsProvider = JsProvider;
exports.jsProvider = jsProvider;
exports.useSavedSearchesEnabled = useSavedSearchesEnabled;
exports.useRelayConversationsPageEnabled = useRelayConversationsPageEnabled;
/* allKeys Not a pure module */
